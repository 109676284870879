import React from 'react'
import { getStringByPropertyName } from "../../../../Core/Util/Helpers"
import { theme } from "../../../../Theme"
import { styled } from 'linaria/react'
import { cx } from 'linaria'

const ResponsiveUspListWrapper = styled.div`
   height: 30px;
   display: none;

   ${theme.above.sm} {
      display: block;
   }

   .usp-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      column-gap: 1rem;
      overflow: hidden;
      height: 100%;
      ${theme.above.lg} {
         justify-content: center;
      }
   }

   .usp-container > :first-child {
      height: 100%;
   }

   .usp-item {
      flex: 0 1 auto;
      display: flex;
      align-items: center;
      color: white;
      white-space: nowrap;
      font-weight: 500;
      /* font-family: ${theme.font.family.heading}; */
      font-family: var(--font-grotesk);
      font-size: ${theme.font.size.xs};
      ${theme.above.xxxl} {
         font-size: ${theme.font.size.s};
      }
   }
`

const ResponsiveUspList = ({ usps }) => {
   return (
      <ResponsiveUspListWrapper>
         <div className="usp-container" tabIndex="-1">
            {usps?.map(({ properties }, i) => {
               const uspContent = getStringByPropertyName(properties, 'title')
               return (
                  <span tabIndex="-1" key={i} className={cx('usp-item')}>
                     {/* ✓ */}
                     {uspContent}
                  </span>
               )
            })}
         </div>
      </ResponsiveUspListWrapper>
   )
}

export default ResponsiveUspList