import React, { useState, useContext, useEffect, useRef } from 'react'
import { CartAlt, Cancel as CancelIcon } from 'iconoir-react'
import CartProvider from '@jetshop/core/components/Query/CartProvider'
import useRemoveFromCart from '@jetshop/core/components/Mutation/useRemoveFromCart'
import { Price } from '@jetshop/ui/Price'
import cartQuery from './CartQuery.gql'
import removeFromCartMutation from './removeFromCart.gql'
import setQuantityMutation from './setQuantity.gql'
import Drawer from 'react-modern-drawer'
import { styled } from 'linaria/react'
import Title from '../Core/Title'
import { PriceFormatter } from '../Global/PriceFormatter'
import VbDrawer from '../Core/VbDrawer'
import ProductCartCard from '../Core/Cards/ProductCartCard'
import useSetQuantity from '@jetshop/core/components/Mutation/useSetQuantity'
import SiteButton from '../Core/SiteButton'
import { formatDate, formatPrice } from '../Core/Util/Helpers'
import ChannelContext from '@jetshop/core/components/ChannelContext'
import { SiteContext } from '../Global/SiteContext'
import { useIntl } from '@jetshop/intl'
import { useQuery } from 'react-apollo'
import Swal from 'sweetalert2'
import { useSiteSettingsQuery } from '../../hooks/useSiteSettings'
import { useTracker } from '@jetshop/core/analytics/Analytics'
import { ShippingAlert } from './ShippingAlert'
import Link from '../Core/Link'
import useEdgeFinans from './Hooks/useEdgeFinans'

// import { VALID_PREBOOK_NAMES } from '../Core/Util/Constants'

const CartProductContainer = styled.div`
   background-color: #fff;
   border-radius: 0.5rem;

   .product-cart-image {
      width: 100%;
   }
`

const CartResultContainer = styled.div``

const CartNotificationContainer = styled.div`
   background: #fdefbb;
   padding: 1rem;
   border-radius: 1rem;
   border: 0.25rem solid #fed336;

   .cart-message-title {
      position: relative;
   }

   .cart-message-title h4 {
      font-family: var(--font-grotesk);
      font-weight: 700;
   }

   .cart-message-icon {
      width: 100%;
      height: 100%;
      border-radius: 50px;
   }

   .cart-message-icon svg {
      position: absolute;
      border-radius: 50px;
      right: 0;
      top: 0;
   }

   .cart-message-text {
      font-weight: 300;
   }

   .cart-message-date {
      font-weight: 700;
      white-space: nowrap;
   }

   .cart-message-art-name {
      font-weight: 700;
      word-break: break-word;
   }

   .prebook-list-item {
      font-size: 0.9rem;
   }
`
const EdgeFinansContainer = styled.div`
   /* background-color: white;
   border: 1px solid var(--color-edge-finans-gray);
   border-radius: 8px;
   padding: 8px;

   .edge-finans-info {
      font-size: 0.8rem;
      color: var(--color-edge-finans-gray);
      font-family: var(--font-grotesk);
   }

   .edge-finans-info:first-child {
      font-weight: 700;
   } */
`

const CartDrawer = ({ isOpen, setIsOpen, cart }) => {
   const { showVat } = useContext(SiteContext)
   const selectedChannel = useContext(ChannelContext)?.selectedChannel
   const [getSetting, loading] = useSiteSettingsQuery()
   const { handleEdgeFinansCheckout, edgeFinansData, edgeFinansIsLoading } = useEdgeFinans(cart)
   const alertIds = getSetting('productIds') ?? ''
   const track = useTracker()
   const t = useIntl()

   const showPreBookDialog = cart?.items?.some(
      item => item?.product?.stockStatus?.stockStatusId === '14'
   )

   function isValidPreBookItem(item) {
      return item && item?.product?.stockStatus?.stockStatusId === '14'
   }

   // const showPreBookDialog = cart?.items?.some(item =>
   //    item?.customerComments?.some(c => VALID_PREBOOK_NAMES?.includes(c?.name))
   // );

   // function getItemIfValidPreBookData(item) {
   //    return item && VALID_PREBOOK_NAMES?.includes(item?.name) ? item : null;
   // }

   // function isValidPreBookItem(item) {
   //    return item && VALID_PREBOOK_NAMES?.includes(item?.name);
   // }

   // const { data, refetch } = useQuery(cartQueryStockStatus, {
   //    variables: { cartId: cart?.id ?? "" },
   // })

   // useEffect(() => {
   //    refetch();
   //  }, [refetch]);

   const { removeFromCart } = useRemoveFromCart({
      removeFromCartMutation,
      cartQuery,
   })

   const removeProduct = (product, id) => {
      removeFromCart({
         itemId: id,
         product: product,
      })
   }

   const { setQuantity } = useSetQuantity({
      setQuantityMutation,
      cartQuery,
   })

   const updateQuantity = (id, newQty) => {
      setQuantity({
         itemId: id,
         quantity: newQty,
      })
   }

   const handleCheckoutClick = event => {
      event.preventDefault()
      ShippingAlert(cart, alertIds, track, selectedChannel)
   }

   return (
      <VbDrawer
         isOpen={isOpen}
         setIsOpen={setIsOpen}
         title={t('your shopping cart')}
         backgroundColor={'white'}
      >
         <div className="my-32">
            {cart?.items?.map((product, i) => {
               const isPackageItem =
                  Array.isArray(product?.discounts) &&
                  product?.discounts?.some(d => d?.name === 'Paket')

                  const preFormattedProduct = {
                     ...product?.product,
                     previousPrice: isPackageItem
                        ? product?.previousUnitPrice
                        : product?.variant?.previousPrice,
                     price: isPackageItem
                        ? product?.unitPrice
                        : product?.variant?.price,
                     isPackageItem: isPackageItem,
                     articleNumber: product?.articleNumber,
                     subName: product?.subHeading,
                     isPrebook: product?.stockStatus?.stockStatusId === '14',
                  }

               // Prebook comments added programmatically not by customer
               // const preBookCommentData = getItemIfValidPreBookData(
               //    product?.customerComments?.[0]
               // )

               return (
                  <React.Fragment key={i}>
                     <CartProductContainer className="mb-16">
                        <ProductCartCard
                           product={preFormattedProduct}
                           updateQuantity={updateQuantity}
                           removeProduct={removeProduct}
                           qty={product?.quantity}
                           // preBookCommentData={preBookCommentData}
                           cartItemId={product?.id}
                           setIsOpen={setIsOpen}
                        ></ProductCartCard>
                     </CartProductContainer>
                  </React.Fragment>
               )
            })}
         </div>
         {cart?.items?.length > 0 && (
            <CartResultContainer>
               <div className="d-flex justify-content-between">
                  <Title size={'20px'} weight={600}>
                     {showVat ? t('of which VAT') : t('+ VAT')}
                  </Title>
                  <Title size={'20px'} weight={600}>
                     {formatPrice(cart?.productTotal?.vat, selectedChannel?.id)}
                  </Title>
               </div>
               <hr className="mt-8 mb-8"></hr>
               <div className="d-flex justify-content-between">
                  <Title size={'32px'} weight={700}>
                     {t('Total')}
                  </Title>
                  <Title size={'32px'} weight={700}>
                     {formatPrice(
                        showVat
                           ? cart?.productTotal?.incVat
                           : cart?.productTotal?.exVat,
                        selectedChannel?.id
                     )}
                  </Title>
               </div>
               <SiteButton
                  size="medium"
                  onClick={handleCheckoutClick}
                  styleType="solid"
                  color={'green'}
                  className="w-100 mt-8 py-8"
               >
                  {t('Check out')}
               </SiteButton>

               {!showVat && selectedChannel?.id == 1 && edgeFinansData && cart?.productTotal?.exVat >= 10000 &&  (
                  <EdgeFinansContainer className="mt-16" >
                     <SiteButton
                        size="medium"
                        disabled={edgeFinansIsLoading || typeof edgeFinansData?.monthlyPayment !== 'number'}
                        color="blue"
                        styleType="solid"
                        className="w-100 mt-8 py-8"
                        onClick={() => {
                           handleEdgeFinansCheckout({
                              cart: cart,
                              redirectNewWindow: false,
                           })
                        }}
                     >
                        {(typeof edgeFinansData?.monthlyPayment === 'number' && !edgeFinansIsLoading) ? (
                           `Finansiering från ${formatPrice(Math.round(edgeFinansData?.monthlyPayment))} kr/mån`) : 
                           edgeFinansIsLoading ? "Laddar..." : "Finansiering"
                        }
                     </SiteButton>
                  </EdgeFinansContainer>
               )}
            </CartResultContainer>
         )}

         {showPreBookDialog && (
            <CartNotificationContainer className="mt-16">
               <div className="cart-message-title">
                  <h4>{t('note')}</h4>
                  <div className="cart-message-icon">
                     <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                     >
                        <g
                           data-name="Group 326"
                           transform="translate(-1740.102 -1001)"
                        >
                           <circle
                              data-name="Ellipse 65"
                              cx="16"
                              cy="16"
                              r="16"
                              transform="translate(1740.102 1001)"
                              fill="rgb(255, 211, 54)"
                           />
                           <path
                              data-name="Path 393"
                              d="M1756.05 1012.828v5.069m0 3.388.008-.009m-.008 4.228a8.449 8.449 0 1 0-8.449-8.451 8.448 8.448 0 0 0 8.449 8.451Z"
                              fill="none"
                              stroke="#fff"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2.5"
                           />
                        </g>
                     </svg>
                  </div>
               </div>
               <div className="cart-message-text pb-8">
                  {t('longer delivery time message')}
               </div>

               {cart?.items
                  ?.filter(item =>
                     // isValidPreBookItem(item?.customerComments?.[0])
                     isValidPreBookItem(item)
                  )
                  .map((item, i) => {
                     const { value: preBookCommentDate } =
                        item?.customerComments?.[0] ?? {}
                     return (
                        <li key={i} className="prebook-list-item">
                           <span className="cart-message-art-name">
                              {' '}
                              {item?.product?.name}{' '}
                           </span>
                           {preBookCommentDate && (
                              <>
                                 <span className="cart-message-text">
                                    {t('back in stock message')}{' '}
                                 </span>{' '}
                                 <span className="cart-message-date">
                                    {preBookCommentDate}
                                 </span>
                              </>
                           )}
                        </li>
                     )
                  })}
            </CartNotificationContainer>
         )}
      </VbDrawer>
   )
}

const CartIcon = () => {
   const [isOpen, setIsOpen] = useState(false)
   return (
      <>
         <CartProvider query={cartQuery}>
            {result => {
               const cart = result?.data?.cart
               const itemsInCart = cart?.totalQuantity || 0

               return (
                  <>
                     <Link
                        className="my-account-link"
                        to="/"
                        onClick={event => {
                           event.preventDefault()
                           setIsOpen(!isOpen)
                        }}
                     >
                        <CartAlt strokeWidth={2.0}></CartAlt>
                        {itemsInCart > 0 && (
                           <div className="badge-counter">
                              <span>{itemsInCart}</span>
                           </div>
                        )}
                     </Link>
                     <CartDrawer
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                        cart={cart}
                     ></CartDrawer>
                  </>
               )
            }}
         </CartProvider>
      </>
   )
}

export default CartIcon
